<template>
  <v-img
    src="@/assets/bg_landing.jpg"
    lazy-src="@/assets/bg_landing_small.jpg"
    height="100%"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
    >
      <v-card class="mr-3 mt-10 justify-center" width="450px" color="white">
        <v-card-title class="d-flex" style="justify-content: space-between;">
          <v-img src="@/assets/title_logo.svg" max-width="270px" />
        </v-card-title>
        <v-card-title class="mx-3 pb-0 d-flex align-center justify-center pt-0">
          <div style="font-family: 'Poppins-SemiBold'; color: #7024c4">
            Reset Password
          </div>
        </v-card-title>
        <v-card-text
          style="font-family: 'Poppins-SemiBold'; color: #7024c4"
          class="px-10 mt-8 pb-0"
        >
          In order to protect your account, make sure your password is longer
          than 8 characters.
        </v-card-text>
        <v-card-title class="mx-3 mb-0 pb-0 mt-5">
          <v-text-field
            v-model="new_password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="New password"
            dense
            filled
            outlined
            background-color="white"
            class="pb-0 mb-0"
            full-width
            style="width: 100%"
            @keypress.enter="loginClicked"
          />

          <v-text-field
            v-model="confirm_password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.passwordMatch]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="Confirm password"
            dense
            filled
            outlined
            background-color="white"
            class="pb-0 mb-0"
            full-width
            style="width: 100%"
            @keypress.enter="loginClicked"
            :error-messages="errorMessage"
          />
        </v-card-title>

        <v-card-title
          class="mt-0 pt-0 d-flex align-center justify-center px-7 mt-3"
        >
          <v-btn
            dark
            color="#7024c4"
            width="100%"
            @click="resetPasswordClicked"
            :loading="loading"
          >
            Reset Password
          </v-btn>
        </v-card-title>
        <v-card-title
          class="mt-0 pt-0 d-flex align-center justify-center mb-5 px-7"
        >
          <v-btn text color="#7024c4" width="100%" @click="onBack">
            back
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      new_password: "",
      confirm_password: "",
      loading: false,
      errorMessage: null,
      showPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.new_password != this.confirm_password
            ? `Password doesn't match`
            : "",
      },
    };
  },
  methods: {
    ...mapActions({
      onResetPassword: "auth/onResetPassword",
    }),

    resetPasswordClicked() {
      if (this.loading) return;

      if (this.new_password != this.confirm_password) {
        return;
      }
      if (!this.new_password) return;
      this.loading = true;
      this.onResetPassword({
        ...this.$route.params,
        password: this.new_password,
      })
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    onBack() {
      this.$router.back();
    },
  },
};
</script>
<style>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 50px;
  text-shadow: 2px 2px 12px #0006;
  color: white;
  padding-left: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  padding-left: 20px;
  display: block;
}
.bg-blur {
  background: #fffc;
  position: absolute;
  height: 100%;
  width: 100%;
}
.bg-cover > .v-image__image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
